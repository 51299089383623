* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.df-jc-ac {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.df-jc {
  display: flex;
  justify-content: center;
}

.df-jb {
  display: flex;
  justify-content: space-between;
}

.df-ac {
  display: flex;
  align-items: center;
}


.ant-layout-header .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: white;
}

.ant-layout-header .trigger:hover {
  color: #1890ff;
}

.ant-layout-sider-children {
  .logo  {
    height: 64px;
    padding: 16px 24px;
    // background: rgba(255, 255, 255, 0.3);
    @extend .df-ac;
    color: white;
  }
  .ant-menu-item {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  
}

.site-layout .site-layout-background {
  margin: 0 !important;
  @extend .df-jb;
  .right-notification-userInfo {
    padding: 0 24px;
    .ant-badge {
      margin-right: 15px;
    }
    .ant-avatar {
      border: 1px solid white;
    }
    svg {
      color: white;
      height: 25px;
      width: 25px;
      background-color: red;
      border-radius: 50%;
    }
  }
}
.ant-layout-content {
  background: #efefef !important;
  height: calc(100vh - 114px);
  overflow-y: auto;
  padding: 24px 24px 30px 24px !important;
  &::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: red; 
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }
}

.ant-drawer-body {
  padding: 0 !important;
  .ant-menu-item {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

.ant-drawer-header{
  @extend .df-jb;
  .ant-drawer-close {
    position: static;
    display: inline-block;
    padding: 0;
  }
}

a {
  text-decoration: none !important;
}

.ant-pagination-item-link {
  @extend .df-jc-ac;
}

.ant-pagination-prev, .ant-pagination-next {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-layout-footer {
  @extend .df-jc-ac;
  height: 50px;
  padding: 0;
}

.ant-table-pagination.ant-pagination {
  padding: 16px 0;
  margin: 0 !important;
}

.ant-form-item-children-icon {
  @extend .df-jc-ac;
  top: 0;
  height: 100%;
}

.site-border {
  border: 1px solid #ccc;
}

.ant-form-item-control-input-content {
  justify-content: flex-end;
  display: flex;
}

.date-box {
  @extend .df-jc;
}

.check-span{
  cursor: pointer;
}

// .left-menu{
//   height: calc(100vh - 64px);
//   overflow-y: auto;
// }

.ant-table-title {
  display: flex;
  justify-content: flex-end;
}


.template-modal {
  top: 10px !important;
  .ck-editor__main {
    div {
      height: calc(100vh - 250px);
    }
  }
}

.booking-modal{
  top: 10px !important;
  .ant-modal-body{
    height: calc(100vh - 150px);
    .ck-editor__main {
      div {
        height: calc(100vh - 280px);
      }
    }
  }
  .ant-tabs-tab {
    padding: 8px 10px 8px 0 !important;
  }
}