.ordered {
  pointer-events: none;
  color: white !important;
  font-weight: 700 !important;
  background-color: blue !important;
  cursor: not-allowed !important;
}
.reserved {
  pointer-events:none;
  background-color: green !important;
  font-weight: 700 !important;
  color: white !important;
}
.approved {
  pointer-events: none;
  background-color: yellow !important;
  font-weight: 700 !important;
  color: blue !important;
}
